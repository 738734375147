import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _class;
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
const SESSION_COOKIE_KEY = "token-companion";
const PARTIAL_SESSION_COOKIE_KEY = "partialAuth-companion";
const nextSteps = ["phoneOtp", "changePassword", "cognitoMigrateAddPhone"];
function isNextStep(str) {
  return !!nextSteps.find(n => str === n);
}
const getCookieValue = (cookieStore, key) => {
  var _cookieStore$split$ma;
  return (_cookieStore$split$ma = cookieStore.split(";").map(cookieString => cookieString.trim()).map(cookieString => cookieString.split("=")).find(cookie => cookie[0] === key)) === null || _cookieStore$split$ma === void 0 ? void 0 : _cookieStore$split$ma[1];
};
const getSessionCompanion = (cookieStore, key) => JSON.parse(getCookieValue(cookieStore, key) || '{}');
export class Session {
  constructor(authenticated, expiry, nextStep) {
    // Typeguard
    _defineProperty(this, "isPartial", () => typeof this.nextStep !== 'undefined' && !this.isAuthenticated);
    this.isAuthenticated = authenticated;
    this.nextStep = nextStep;
    this.expiry = expiry;
  }
}
_class = Session;
_defineProperty(Session, "makeAuthenticated", (user, cookieStore) => new AuthenticatedSession(user, cookieStore));
_defineProperty(Session, "makeUnauthenticated", () => UnauthenticatedSession);
_defineProperty(Session, "maybePartial", cookieStore => {
  var _getSessionCompanion;
  const next = (_getSessionCompanion = getSessionCompanion(cookieStore, PARTIAL_SESSION_COOKIE_KEY)) === null || _getSessionCompanion === void 0 ? void 0 : _getSessionCompanion.next;
  if (next && isNextStep(next)) {
    return new PartialSession(next);
  }
});
_defineProperty(Session, "isAuthenticated", cookieStore => () => cookieStore.includes(SESSION_COOKIE_KEY));
_defineProperty(Session, "getExpiry", cookieStore => () => {
  var _getSessionCompanion2;
  return pipe(O.fromNullable((_getSessionCompanion2 = getSessionCompanion(cookieStore, SESSION_COOKIE_KEY)) === null || _getSessionCompanion2 === void 0 ? void 0 : _getSessionCompanion2.expiresIn), O.fold(() => O.some(0),
  // If no cookie is present, the expiry is in the past
  value => O.some(Number(value))));
});
// Can be removed after 30 days of migration period
_defineProperty(Session, "maybeGetRefreshToken", () => {
  let token = null;
  if (typeof process.env.RAZZLE_PC_USER_POOL_WEBCLIENT_ID !== 'undefined') {
    token = _class.getLocalStorageRefreshToken(process.env.RAZZLE_PC_USER_POOL_WEBCLIENT_ID);
    if (token === null && typeof process.env.RAZZLE_BC_COGNITO_CLIENTID !== 'undefined') {
      token = _class.getLocalStorageRefreshToken(process.env.RAZZLE_BC_COGNITO_CLIENTID);
    }
  }
  return token;
});
// Can be removed after 30 days of migration period
_defineProperty(Session, "maybeDeleteRefreshToken", () => {
  if (typeof process.env.RAZZLE_PC_USER_POOL_WEBCLIENT_ID !== 'undefined') {
    _class.deleteLocalStorageRefreshToken(process.env.RAZZLE_PC_USER_POOL_WEBCLIENT_ID);
  }
  if (typeof process.env.RAZZLE_BC_COGNITO_CLIENTID !== 'undefined') {
    _class.deleteLocalStorageRefreshToken(process.env.RAZZLE_BC_COGNITO_CLIENTID);
  }
});
// Can be removed after 30 days of migration period
_defineProperty(Session, "getLocalStorageRefreshToken", poolClientId => {
  const keyPrefix = `CognitoIdentityServiceProvider.${poolClientId}`;
  try {
    if (window.localStorage.getItem(`${keyPrefix}.LastAuthUser`)) {
      return window.localStorage.getItem(`${keyPrefix}.${window.localStorage.getItem(keyPrefix + '.LastAuthUser')}.refreshToken`);
    }
  } catch (exception) {
    return null;
  }
  return null;
});
// Can be removed after 30 days of migration period
_defineProperty(Session, "deleteLocalStorageRefreshToken", poolClientId => {
  const keyPrefix = `CognitoIdentityServiceProvider.${poolClientId}`;
  try {
    if (window.localStorage.getItem(`${keyPrefix}.LastAuthUser`)) {
      window.localStorage.removeItem(`${keyPrefix}.${window.localStorage.getItem(keyPrefix + '.LastAuthUser')}.refreshToken`);
      window.localStorage.removeItem(`${keyPrefix}.LastAuthUser`);
    }
  } catch (exception) {
    return null;
  }
});
export class AuthenticatedSession extends Session {
  constructor(user, cookieStore) {
    super(true, Session.getExpiry(cookieStore)(), undefined);
    this.user = user;
  }
}
export class PartialSession extends Session {
  constructor(nextStep) {
    super(false, O.none, nextStep);
  }
}
export class _UnauthenticatedSession extends Session {
  constructor() {
    super(false, O.none, undefined);
  }
}
export const UnauthenticatedSession = new _UnauthenticatedSession();