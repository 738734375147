import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pipe } from "fp-ts/lib/function";
import * as TE from "fp-ts/TaskEither";
export class Routes {
  constructor(env) {
    this.env = env;
  }
}
const fetchParams = {
  mode: "same-origin",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json"
  },
  redirect: "follow"
};

/**
 * Auth API class
 * constructs an API class for the given routes
 */
export class API {
  constructor(routes, env) {
    _defineProperty(this, "errorCodeFromStatus", status => {
      switch (status) {
        case 401:
          return "Unauthorized";
        case 403:
          return "Forbidden";
        default:
          return "UnknownError";
      }
    });
    _defineProperty(this, "handleApiResponse", response => pipe(TE.tryCatch(async () => {
      var _response$headers$get;
      const isJson = !!((_response$headers$get = response.headers.get("content-type")) !== null && _response$headers$get !== void 0 && _response$headers$get.includes("application/json"));
      return isJson ? JSON.parse((await response.text.bind(response)()) || "{}") // We default to an empty object to not surely rely on the response header
      : undefined;
    }, error => {
      console.error(`Error decoding API response as JSON although response content-type is "application/json": \n${error}`);
      return this.errorCodeFromStatus(response.status);
    }), TE.flatMap(parsed => {
      if (response.ok) {
        return TE.right(parsed);
      } else {
        return TE.left((parsed === null || parsed === void 0 ? void 0 : parsed.error) || this.errorCodeFromStatus(response.status));
      }
    })));
    _defineProperty(this, "handleApiError", (error, route) => {
      if (/{([^{}]+)}/g.test(route)) {
        console.warn(`Request URL ${route} contains unreplaced path placeholer. Did you mean to replace it?`);
      }
      console.error(`Auth API error: \n${error}`);
      return "EndpointUnavailable";
    });
    /**
     * Delete an entity
     * @param {string}  route the endpoint to delete data from
     * @returns {TE.TaskEither<ApiErrorCode, T>} the response from the endpoint or and API failure
     */
    _defineProperty(this, "delete", (route, params) => pipe(TE.tryCatch(() => fetch(route, {
      ...fetchParams,
      method: "DELETE",
      body: JSON.stringify(params)
    }), error => this.handleApiError(error, route)), TE.flatMap(this.handleApiResponse)));
    /**
     * Put data to a auth endpoint
     * @param {string}  route the endpoint to put data to
     * @param {Object}  params  any json serializable object
     * @returns {TE.TaskEither<ApiErrorCode, T>} the response from the endpoint or and API failure
     */
    _defineProperty(this, "put", (route, params) => pipe(TE.tryCatch(() => fetch(route, {
      ...fetchParams,
      method: "PUT",
      body: JSON.stringify(params)
    }), error => this.handleApiError(error, route)), TE.flatMap(this.handleApiResponse)));
    /**
     * Post data to a auth endpoint
     * @param {string}  route the endpoint to post data to
     * @param {Object}  params  any json serializable object
     * @returns {TE.TaskEither<ApiError, T>} the response from the endpoint or and API failure
     */
    _defineProperty(this, "post", (route, params) => pipe(TE.tryCatch(() => fetch(route, {
      ...fetchParams,
      method: "POST",
      body: JSON.stringify(params)
    }), error => this.handleApiError(error, route)), TE.flatMap(this.handleApiResponse)));
    /**
     * Get data from an API endpoint
     * @param {string}  route  the endpoint to fetch data from
     * @returns {TE.TaskEither<ApiError, T>} the data from the endpoint or an API failure
     */
    _defineProperty(this, "get", (route, queryParams) => pipe(TE.tryCatch(() => fetch(`${route}${queryParams ? "?" : ""}${new URLSearchParams(queryParams)}`, {
      ...fetchParams,
      method: "GET"
    }), error => this.handleApiError(error, route)), TE.flatMap(r => this.handleApiResponse(r))));
    this.routes = routes;
    this.env = env;
  }
}
export default API;