export function getLocale() {
  var allowed = ['de', 'at', 'pl'];
  var path = typeof window !== 'undefined' && window.location.pathname;

  // return default if the given path does not contain a locale
  if (path === undefined || path === '' || path === '/') return allowed[0];

  // remove leading slash if it exists
  if (path && path.indexOf('/') === 0) path = path.substr(1);
  var locale = allowed[0];
  if (path && path.indexOf('/') > -1) {
    locale = path.split('/')[0];
  } else locale = path;
  if (allowed.includes(locale)) {
    return locale === 'at' ? 'de' : locale;
  } else return allowed[0];
}