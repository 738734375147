import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pipe } from "fp-ts/lib/function";
import Enviroment from "./Enviroment";
import * as TE from "fp-ts/TaskEither";
import { PartialSession, Session } from "./Session";
import API, { Routes } from "@jobmatchme/effectful-api/es/API";
export class AuthRoutes extends Routes {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "register", `${this.env.endpoint}/auth/`);
    _defineProperty(this, "auth", `${this.env.endpoint}/auth/`);
    _defineProperty(this, "refresh", `${this.env.endpoint}/accounts/auth/refresh`);
    _defineProperty(this, "me", `${this.env.endpoint}/accounts/users/me`);
    _defineProperty(this, "contact", `${this.env.endpoint}/accounts/users/me/{contactType}`);
    _defineProperty(this, "requestContactVerificationToken", `${this.env.endpoint}/accounts/users/me/{contactType}/verify`);
    _defineProperty(this, "verifyContact", `${this.env.endpoint}/accounts/token/{contactType}/{token}`);
    _defineProperty(this, "mainContact", `${this.env.endpoint}/accounts/users/me/{contactType}/main`);
    _defineProperty(this, "logout", `${this.env.endpoint}/accounts/auth/logout`);
    _defineProperty(this, "requestPasswordReset", `${this.env.endpoint}/accounts/resetPassword`);
    _defineProperty(this, "resetPassword", `${this.env.endpoint}/accounts/token/password/{token}`);
    _defineProperty(this, "changePassword", `${this.env.endpoint}/accounts/users/me/password`);
  }
}
export class RegistrationRequest {
  constructor(type, name, locale, gender) {
    this.type = type;
    this.name = name;
    this.locale = locale;
    this.gender = gender;
  }
}
export class DefaultRegistrationRequest extends RegistrationRequest {
  constructor(name, locale, gender, email, phone, password, phoneRegion) {
    super("password", name, locale, gender);
    this.name = name;
    this.locale = locale;
    this.gender = gender;
    this.email = email;
    this.phone = phone;
    this.phoneRegion = phoneRegion;
    this.password = password;
  }
}
export class PhoneOtpRegistrationRequest extends RegistrationRequest {
  constructor(phone, name, locale, gender, email) {
    super("phone", name, locale, gender);
    this.name = name;
    this.locale = locale;
    this.gender = gender;
    this.phone = phone;
    this.email = email;
  }
}
export class LoginRequest {
  constructor(type) {
    this.type = type;
  }
}
export class OtpConfirmationRequest extends LoginRequest {
  constructor(token) {
    super("token");
    this.token = token;
  }
}
export class PhoneOtpConfirmationRequest extends OtpConfirmationRequest {}
export class PasswordLoginRequest extends LoginRequest {
  constructor(user, password) {
    super("password");
    _defineProperty(this, "type", "password");
    this.user = user;
    this.password = password;
  }
}
export class PhoneLoginRequest extends LoginRequest {
  constructor(phone) {
    super("phone");
    _defineProperty(this, "type", "phone");
    this.phone = phone;
  }
}
export class EmailLoginRequest extends LoginRequest {
  constructor(email) {
    super("email");
    _defineProperty(this, "type", "email");
    this.email = email;
  }
}
export class MigrationRequest extends LoginRequest {
  constructor(refreshToken) {
    super("token");
    _defineProperty(this, "type", "token");
    this.refreshToken = refreshToken;
  }
}
export class MigrationAddPhoneRequest extends LoginRequest {
  constructor(phone) {
    super("migrate");
    _defineProperty(this, "type", "migrate");
    this.phone = phone;
  }
}
export class SetPasswordLoginRequest extends LoginRequest {
  constructor(newPassword) {
    super("resolveStatus");
    _defineProperty(this, "type", "resolveStatus");
    this.newPassword = newPassword;
  }
}
/**
 * Auth API class
 * constructs an API class for the given routes
 */
class AuthAPI extends API {
  constructor(routes = new AuthRoutes(Enviroment), env = Enviroment) {
    super(routes, env);
    /**
     * Rergister a new user
     * @param   {RegistrationRequest}   params  the userdata to register
     * @returns {TE.TaskEither<RegistrationError | GenericError, Session>} The id an login status of the newly created user or an API Failure
     */
    _defineProperty(this, "register", params => this.auth(params));
    /**
     * Get the userdata for the currently logged in user
     * @returns {TE.TaskEither<ApiError, User>} the users data or an API failure
     */
    _defineProperty(this, "getUserData", () => this.get(this.routes.me));
    /**
     * Attempt to authenticate the a new session for a given user
     * @param {LoginRequest}  params  the users login data
     * @returns {TE.TaskEither<ApiError, Session>} a new session or an API failure
     */
    _defineProperty(this, "login", params => this.auth(params));
    /**
     * Attempt to authenticate the a new session for a given user
     * @param {LoginRequest | RegistrationRequest}  params  the users login data
     * @returns {TE.TaskEither<ApiError, Session>} a new session or an API failure
     */
    _defineProperty(this, "auth", params => pipe(this.post(this.routes.auth, params), TE.flatMap(response => {
      switch (response.next) {
        case undefined:
          {
            const auth = response;
            return pipe(this.getUserData(), TE.map(user => ({
              user,
              permissions: auth.permissions
            })), TE.map(({
              user
            }) => Session.makeAuthenticated(user, document.cookie)));
          }
        case "phoneOtp":
          return TE.right(new PartialSession("phoneOtp"));
        case "cognitoMigrateAddPhone":
          return TE.right(new PartialSession("cognitoMigrateAddPhone"));
        case "changePassword":
          return TE.right(new PartialSession("changePassword"));
        default:
          return TE.left("InvalidResponse");
      }
    })));
    /**
     * Attempt to refresh a session
     * @returns {TE.TaskEither<GenericError, void>}
     */
    _defineProperty(this, "refresh", () => pipe(this.post(this.routes.refresh)));
    /**
     * Terminate the current session
     * @returns {TE.TaskEither<ApiError, User>} the success state or an API failure
     */
    _defineProperty(this, "logout", () => this.post(this.routes.logout));
    /**
     * Change your password
     * @param {string}  oldPassword  the old password
     * @param {string}  newPassword  the new password
     * @returns {TE.TaskEither<ApiError, void>} success state or an API failure
     */
    _defineProperty(this, "changePassword", (oldPassword, newPassword) => this.put(this.routes.changePassword, {
      oldPassword,
      newPassword
    }));
    /**
     * Request a password reset. The token for authorizing the reset will be sent to the given email
     * @param {string}  email  the email adress of the user whose password should be resetted
     * @returns {TE.TaskEither<ApiError, Session>} success state or an API failure
     */
    _defineProperty(this, "requestPasswordReset", email => this.post(this.routes.requestPasswordReset, {
      email
    }));
    /**
     * Authorize a password reset
     * @param {string}  token        the recieved token to authenticate the reset
     * @param {string}  newPassword  the users new password
     * @returns {TE.TaskEither<ApiError, Session>} success state or an API failure
     */
    _defineProperty(this, "resetPassword", (token, newPassword) => this.post(this.routes.resetPassword.replace("{token}", token), {
      newPassword
    }));
    /**
     * Update the data of the current user
     * @param {UpdateUserDataRequest}  params  The updated dataset
     * @returns {TE.TaskEither<GenericError, Session>} success state or an API failure
     */
    _defineProperty(this, "updateUserData", params => this.put(this.routes.me, params));
    /**
     * Delete the current account
     * @returns {TE.TaskEither<GenericError, void>} success state or an API failure
     */
    _defineProperty(this, "deleteAccount", () => this.delete(this.routes.me));
    /**
     * Add a new contact to the account
     * @param {ContactType}  contactType  the type of contact
     * @param {string}       newContact  the contact to add to the account
     * @returns {TE.TaskEither<GenericError, void>} success state or an API failure
     */
    _defineProperty(this, "addContact", (contactType, newContact) => this.post(this.routes.contact.replace("{contactType}", contactType), {
      [contactType]: newContact
    }));
    /**
     * Delete an existing contact from the account
     * @param {ContactType}  contactType  the type of contact
     * @param {string}       contact  the contact to delete
     * @returns {TE.TaskEither<GenericError, void>} success state or an API failure
     */
    _defineProperty(this, "deleteContact", (contactType, contact) => this.delete(this.routes.contact.replace("{contactType}", contactType), {
      [contactType]: contact
    }));
    //TODO: can we add the contact to delete to the request body or do we need a path that includes the contact?
    /**
     * Request a verification token for a contact
     * @param {ContactType}  contactType  the type of contact
     * @param {string}  contact  the contact to verify
     * @returns {TE.TaskEither<GenericError, void>} success state or an API failure
     */
    _defineProperty(this, "requestContactVerificationToken", (contactType, contact) => this.post(this.routes.requestContactVerificationToken.replace("{contactType}", contactType), {
      [contactType]: contact
    }));
    /**
     * Set a different contact to be the main contact of this type
     * @param {ContactType}  contactType  the type of contact
     * @param {string}  contact  the contact to mark as main
     * @returns {TE.TaskEither<GenericError, void>} success state or an API failure
     */
    _defineProperty(this, "setMainContact", (contactType, contact) => this.put(this.routes.mainContact.replace("{contactType}", contactType), {
      [contactType]: contact
    }));
    /**
     * Verify a contact via a token
     * @param {ContactType}  contactType  the type of contact
     * @param {string}       token        the recieved token to authenticate the address
     * @returns {TE.TaskEither<GenericError, void>} success state or an API failure
     */
    _defineProperty(this, "verifyContact", (contactType, token) => this.get(this.routes.verifyContact.replace("{contactType}", contactType).replace("{token}", token)));
  }
}
export default AuthAPI;