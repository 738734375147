import App from './components/App'
import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import {I18nextProvider} from 'react-i18next'
import {IntlProvider} from 'react-intl'
import * as Sentry from '@sentry/react'
import i18n from './i18n'
import SimpleErrorPage from '@jobmatchme/ui/es/components/SimpleErrorPage'
import {getUrlLocale} from '@jobmatchme/utils/es/getUrlParam'
import TagManager from 'react-gtm-module'

import {createSessionResource} from '@jobmatchme/react-auth/es/Provider'

// Prepare the resource
const SessionResource = createSessionResource().prepare()

const SSRContainer = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-PLHG9BG',
      dataLayerName: 'PageDataLayer'
    }
    document && TagManager.initialize(tagManagerArgs)
  }, [])

  useEffect(() => {
    if (
      typeof navigator !== 'undefined' &&
      navigator.userAgent !== 'critical'
    ) {
      const timer = setTimeout(() => {
        const jssStyles = document.getElementById('jss-server-side')
        if (jssStyles) {
          jssStyles.parentElement.removeChild(jssStyles)
        }
        const ssrStyles = document.getElementById('critical-server-side')
        if (ssrStyles) {
          ssrStyles.parentElement.removeChild(ssrStyles)
        }
      }, 1000)
      return () => clearTimeout(timer)
    }
  })
  return (
    <IntlProvider locale={getUrlLocale()}>
      <I18nextProvider i18n={i18n}>
        <Sentry.ErrorBoundary fallback={SimpleErrorPage}>
          <App sessionResource={SessionResource} />
        </Sentry.ErrorBoundary>
      </I18nextProvider>
    </IntlProvider>
  )
}

const container = document.getElementById('root')

ReactDOM.hydrateRoot(container, <SSRContainer />)

if (module.hot) {
  module.hot.accept()
}
