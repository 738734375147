var STAGE = process.env.REACT_APP_STAGE;
var getHostName = function getHostName() {
  return typeof window !== 'undefined' && window.location ? window.location.hostname : 'www.truck-jobs.com';
};
var appConfig = {
  routes: {
    /* Route to the bcApi service */
    comApi: ''
  }
};
switch (STAGE) {
  case 'prod':
    appConfig.routes.comApi = "https://".concat(getHostName(), "/api");
    break;
  default:
    appConfig.routes.comApi = '/api';
    break;
}
export default appConfig;