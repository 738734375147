import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import semverCompare from 'semver-compare';
import { useLocation } from 'react-router-dom';
var UPDATE_INTERVAL = 1000 * 60 * 60;
var fetchVersions = function fetchVersions(packageName) {
  var fetchHeaders = new Headers();
  fetchHeaders.append('Content-Type', 'application/json');
  var fetchInit = {
    method: 'GET',
    headers: fetchHeaders,
    mode: 'cors',
    cache: 'no-store'
  };
  var versionsRequest = new Request("https://s3.amazonaws.com/cf.".concat(process.env.RAZZLE_STAGE, ".versions.truck-jobs.com/").concat(packageName, ".versions.json"));
  return fetch(versionsRequest, fetchInit).then(function (response) {
    return response.json();
  });
};
export var ReloadOnVersionChange = function ReloadOnVersionChange(_ref) {
  var packagesWithRoutes = _ref.packagesWithRoutes,
    packageVersions = _ref.packageVersions,
    children = _ref.children;
  var location = useLocation();
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    intervalId = _useState2[0],
    setIntervalId = _useState2[1];
  var _useState3 = useState([]),
    _useState4 = _slicedToArray(_useState3, 2),
    packagesWithNewVersion = _useState4[0],
    setPackagesWithNewVersion = _useState4[1];
  var _useState5 = useState([]),
    _useState6 = _slicedToArray(_useState5, 2),
    packagesWithNewStableVersion = _useState6[0],
    setPackagesWithNewStableVersion = _useState6[1];
  useEffect(function () {
    packagesWithNewVersion.forEach(function (pck) {
      packagesWithRoutes[pck].forEach(function (route) {
        if (location.pathname.search(route) > 0) {
          setPackagesWithNewVersion(packagesWithNewVersion.filter(function (elem) {
            return elem !== pck;
          }));
          window.location.reload(true);
        }
      });
    });
  }, [location, packagesWithNewVersion, packagesWithRoutes]);
  useEffect(function () {
    packagesWithNewStableVersion.forEach(function (pck) {
      packagesWithRoutes[pck].forEach(function (route) {
        if (location.pathname.search(route) > 0) {
          setPackagesWithNewStableVersion(packagesWithNewStableVersion.filter(function (elem) {
            return elem !== pck;
          }));
          window.location.reload(true);
        }
      });
    });
  }, [location, packagesWithNewStableVersion, packagesWithRoutes]);
  useEffect(function () {
    var reloadVersions = function reloadVersions() {
      var packages = Object.keys(packagesWithRoutes);
      return setInterval(function () {
        packages && packages.forEach(function (pck) {
          fetchVersions(pck).then(function (serverVersions) {
            if (typeof packageVersions[pck] !== 'string') {
              console.error("Cannot check version: Invalid package version for package ".concat(pck, " -> ").concat(packageVersions[pck]));
              return;
            }
            if (typeof serverVersions.version !== 'string') {
              console.error("Cannot check version: Invalid serverVersions.version -> ".concat(serverVersions.version));
              return;
            }
            if (serverVersions.version && semverCompare(serverVersions.version, packageVersions[pck]) > 0) {
              setPackagesWithNewVersion(function (state) {
                return [].concat(_toConsumableArray(state), [pck]);
              });
            }
            if (semverCompare(serverVersions.latestStableVersion && serverVersions.latestStableVersion, packageVersions[pck]) > 0) {
              setPackagesWithNewStableVersion(function (state) {
                return [].concat(_toConsumableArray(state), [pck]);
              });
            }
          });
        });
      }, UPDATE_INTERVAL);
    };
    setIntervalId(reloadVersions(Object.keys(packagesWithRoutes)));
    return clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
};