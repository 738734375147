export const genericError = ["ResponseUnreadable", "EndpointUnavailable", "InvalidResponse", "UnknownError", "Unauthorized", "Forbidden"];
export const loginError = [...genericError, "InvalidCredentials"];
export const registrationError = [...genericError, "NameInvalid", "EmailInvalid", "EmailNotUnique", "PhoneInvalid", "PhoneNotUnique", "PasswordInvalid"];
export const changePasswordError = [...genericError, "NewPasswordInvalid", "PasswordInvalid", "UserNotFound"];
export class APIFailure extends Error {
  constructor(error) {
    super(error);
    this.error = error;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, APIFailure.prototype);
  }
  static apply(error) {
    return new APIFailure(error);
  }
}