export var getUrlVars = function getUrlVars() {
  var vars = {};
  if (typeof window !== 'undefined') {
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
  }
  return vars;
};
export var getUrlParam = function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (typeof window !== 'undefined' && window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
};
export var getUrlLocale = function getUrlLocale() {
  // Config
  var allowed = ['de', 'at', 'pl'];
  var path = allowed[0];
  if (typeof window !== 'undefined' && navigator.userAgent !== 'ReactSnap') {
    path = window.location.pathname;
  }

  // return default if the given path does not contain a locale
  if (path === undefined || path === '' || path === '/') return allowed[0];
  // remove leading slash if it exists
  if (path.indexOf('/') === 0) path = path.substr(1);
  var locale = allowed[0];
  if (path.indexOf('/') > -1) {
    locale = path.split('/')[0];
  } else locale = path;
  if (allowed.includes(locale)) {
    return locale;
  } else return allowed[0];
};